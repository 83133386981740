@font-face {
  font-family: "Epilogue";
  src: local("Epilogue"),
    url("./fonts/Epilogue-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

html {
  scroll-behavior: smooth;
}

body .MuiDataGrid-panel .MuiDataGrid-panelWrapper {
  height: 300px;
}
